import { Container, Box, typographyClasses, Button, Link, Typography } from "@mui/joy";

export default function TwoSidedLayout({
    children,
    reversed,
}: React.PropsWithChildren<{ reversed?: boolean }>) {
    return (
        <Container
            sx={(theme) => ({
                position: 'relative',
                minHeight: '100vh',
                display: 'flex',
                flexDirection: reversed ? 'column-reverse' : 'column',
                alignItems: 'center',
                py: 10,
                gap: 4,
                bgcolor: '#0085DF',
                [theme.breakpoints.up(834)]: {
                    flexDirection: 'row',
                    gap: 6,
                },
                [theme.breakpoints.up(1199)]: {
                    gap: 12,
                },
            })}
        >
            <Box
                sx={(theme) => ({
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '1rem',
                    maxWidth: '50ch',
                    textAlign: 'center',
                    flexShrink: 999,
                    [theme.breakpoints.up(834)]: {
                        minWidth: 420,
                        alignItems: 'flex-start',
                        textAlign: 'initial',
                    },
                    [`& .${typographyClasses.root}`]: {
                        textWrap: 'balance',
                    },
                })}
            >
                <Typography
                    level="h1"
                    fontWeight="xl"
                    fontSize="clamp(1.875rem, 1.3636rem + 2.1818vw, 3rem)"
                    fontFamily="Poppins, sans-serif" 
                    sx={{color: "white"}}
                >
                    ChipIn is launching soon!
                </Typography>
                <Typography
                    level="h4"
                    fontFamily="Poppins, sans-serif"
                    fontWeight="400"
                    sx={{color: "white"}}
                >
                    We streamline the donation process by connecting companies and its employees directly to the charities they wish to support
                </Typography>
                <Typography
                    level="h4"
                    fontFamily="Poppins, sans-serif" 
                    fontWeight="400"
                    sx={{color: "white"}}
                >
                    <b>Simplify</b> employee contributions.
                </Typography>
                <Typography
                    level="h4"
                    fontFamily="Poppins, sans-serif" 
                    fontWeight="400"
                    sx={{color: "white"}}
                >
                    <b>Reduce</b> manual admin.
                </Typography>
                <Typography
                    level="h4"
                    fontFamily="Poppins, sans-serif" 
                    fontWeight="400"
                    sx={{color: "white"}}
                >
                    <b>Raise</b> more.
                </Typography>
                <br />
                <div className="klaviyo-form-T7ycq7" style={{width: '100%'}}></div>
            </Box>
            <Box 
                component="img" 
                width="40vw" 
                src="/Payment Information-pana.png" 
                style={{ objectFit: 'contain' }} 
                sx={(theme) => ({ 
                    objectFit: 'contain', 
                    [theme.breakpoints.down(834)]: {
                        width: "70vw",
                    }
                })} 
                >

                    </Box>
        </Container>
    );
}